import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "mdx-content-starts-here"
    }}>{`MDX content starts here`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`The homepage content here lives in the `}<inlineCode parentName="p">{`src/pages/index.mdx`}</inlineCode>{` directory at the root of your project. Just like the other mdx pages, you can use all of our theme components here without importing them.`}</p>
    </PageDescription>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      